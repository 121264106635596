import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    100: '#3183ce',
    200: '#3183ce',
    300: '#3183ce',
    400: '#3183ce',
    500: '#3183ce',
    600: '#3183ce',
    700: '#3183ce',
    800: '#3183ce',
    900: '#3183ce',
  },
};

const customTheme = extendTheme({ colors });

export default customTheme;
