import { gql } from '@apollo/client';

// USER GET PROFILE QUERY:
export const GET_USER_PROFILE = gql`
  query user {
    me {
      id
      firstName
      lastName
      email
      authKey
      avatar
      systemRole {
        name
        id
        permission
      }
      settings {
        alerts
        captureDuration
        captureLocation
        captureScreen
      }
    }
  }
`;

// NEWS LETTER QUERY:
export const SEND_NEWS_LETTER = gql`
  mutation ($input: String!) {
    newsLetter(email: $input)
  }
`;

// GET PROJECT MANAGER USERS LIST:
export const GET_PROJECT_MANAGER_USERS_LIST = gql`
  query managerUsersList(
    $skip: Int
    $take: Int
    $orderBy: UserOrderByInput
    $query: String!
  ) {
    managerUsersList(skip: $skip, take: $take, orderBy: $orderBy, query: $query)
  }
`;
