import { combineReducers } from 'redux';
// @ts-ignore
import { projectManagersReducers } from './projectManagersReducers.ts';
// @ts-ignore
import userReducer from './user.reducer.ts';
const rootReducer = combineReducers({
  user: userReducer,
  managers: projectManagersReducers,
});

export default rootReducer;
