import React from 'react';
import { Badge, IconButton, Box } from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
function BellButton({ count }) {
  const Navigate = useNavigate();
  return (
    <Box position='relative'>
      <IconButton
        aria-label='Notifications'
        icon={<FiBell />}
        variant='ghost'
        _hover={{ backgroundColor: 'transparent' }}
        _active={{ backgroundColor: 'transparent' }}
        _focus={{ outline: 'none' }}
        onClick={() => {
          Navigate('/manual-time-requests');
        }}
      />
      {count > 0 && (
        <Badge
          position='absolute'
          top='-3px'
          right='-3px'
          fontSize='xs'
          borderRadius='full'
          variant='solid'
          colorScheme='red'
          px={2}
        >
          {count}
        </Badge>
      )}
    </Box>
  );
}
export default BellButton;
