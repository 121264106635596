/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import SidebarWithHeader from '../sections/SidebarWithHeader.tsx';

const AuthLayout = (props) => {
  const [auth, isAuth] = useState(false);
  return (
    <Flex
      direction='column'
      // align='center'
      maxW={{ md: '1600px' }}
      m='0 auto'
      {...props}
    >
      <SidebarWithHeader />
      <Box ml={{ base: 0, md: 60 }} mt='8' p={'4'}>
        {props.children}
      </Box>
      {/* <Footer /> */}
    </Flex>
  );
};
export default AuthLayout;
