// @ts-ignore
import {
  LOGIN,
  LOGOUT,
  PERMISSIONS_UPDATED,
  USER_UPDATED,
  PROFILE_UPDATED,
  COUNT_REQUESTS,
  GET_MANAGERS_LIST,
} from '../actionTypes/index.ts';

const initialUserState = {
  firstName: '',
  lastName: '',
  email: '',
  status: '',
  token: '',
  avatar: '',
  authKey: '',
  loggedIn: false,
  systemPermissions: [],
  countRequests: 0,
};

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        authKey: action.payload.authKey,
        status: action.payload.status,
        token: action.payload.token,
        avatar: action.payload.avatar,
        systemPermissions: action.payload.systemPermissions,
        loggedIn: true,
      };
    case PERMISSIONS_UPDATED:
      return {
        ...state,
        systemPermissions: action.payload.systemPermissions,
      };
    case LOGOUT:
      return {
        ...state,
        email: '',
        firstName: '',
        lastName: '',
        token: '',
        avatar: '',
        systemPermissions: [],
        loggedIn: false,
      };
    case USER_UPDATED:
      return {
        ...state,
        ...action.payload,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        status: action.payload.systemRole.name,
        systemPermissions: action.payload.systemRole.permission,
      };
    case PROFILE_UPDATED:
      return {
        ...state,
        ...action.payload,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        avatar: action.payload.avatar,
        status: action.payload.systemRole.name,
      };
    case COUNT_REQUESTS:
      return {
        ...state,
        ...action.payload,
        countRequests: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
