import React from 'react';

function FacebookIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 12.0614C1 17.5303 4.97192 22.0778 10.1667 23V15.0553H7.41667V12H10.1667V9.55525C10.1667 6.80525 11.9386 5.27808 14.4447 5.27808C15.2386 5.27808 16.0948 5.4 16.8886 5.52192V8.33333H15.4833C14.1386 8.33333 13.8333 9.00525 13.8333 9.86142V12H16.7667L16.2781 15.0553H13.8333V23C19.0281 22.0778 23 17.5312 23 12.0614C23 5.9775 18.05 1 12 1C5.95 1 1 5.9775 1 12.0614Z'
        fill='white'
      />
    </svg>
  );
}

export default FacebookIcon;
