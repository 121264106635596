import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// @ts-ignore
import { store, persistor } from './redux/store.ts';
import { BrowserRouter } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
// @ts-ignore
import App from './App.tsx';
// @ts-ignore
import customTheme from './utils/theme';

const httpLink: any = createUploadLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('accessToken');
  const customHeaders = operation.getContext().hasOwnProperty('headers')
    ? operation.getContext().headers
    : {};

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      ...customHeaders,
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.Fragment>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={customTheme}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.Fragment>
);

reportWebVitals();
