import { GET_MANAGERS_LIST } from '../actionTypes/index.ts';
const initialState = {
  listOfManagers: [],
};

export const projectManagersReducers = (state = initialState, action) => {
  if (action.type === GET_MANAGERS_LIST) {
    return {
      listOfManagers: action.payload,
    };
  } else {
    return state;
  }
};
