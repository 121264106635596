// @ts-ignore
import {
  LOGIN,
  LOGOUT,
  PERMISSIONS_UPDATED,
  USER_UPDATED,
  PROFILE_UPDATED,
  COUNT_REQUESTS,
  GET_MANAGERS_LIST,
} from '../actionTypes/index.ts';

const login = ({
  id,
  firstName,
  lastName,
  companyId,
  email,
  authKey,
  status,
  token,
  avatar,
  systemPermissions,
  settings,
  isTwoFactorAuthenticationEnabled,
}) => {
  return {
    type: LOGIN,
    payload: {
      id,
      firstName,
      lastName,
      companyId,
      email,
      authKey,
      status,
      token,
      avatar,
      systemPermissions,
      settings,
      isTwoFactorAuthenticationEnabled,
    },
  };
};

export const countRequestsUpdated = (count) => {
  return {
    type: COUNT_REQUESTS,
    payload: count,
  };
};
export const getManagersList = (managers) => {
  return {
    type: GET_MANAGERS_LIST,
    payload: managers,
  };
};

const permissionUpdated = ({ systemPermissions }) => {
  return {
    type: PERMISSIONS_UPDATED,
    payload: {
      systemPermissions,
    },
  };
};
export const userUpdated = (data) => {
  return {
    type: USER_UPDATED,
    payload: data,
  };
};
export const profileUpdated = (data) => {
  return {
    type: PROFILE_UPDATED,
    payload: data.updateUser,
  };
};

export const thunkPermissionUpdated = (data: any): any => {
  return async (dispatch) => {
    try {
      await dispatch(
        permissionUpdated({
          systemPermissions: data,
        })
      );
    } catch (error) {
      console.log('Error: ', error.message);
    }
  };
};

const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const thunkLogin = (data, callback) => {
  return async (dispatch) => {
    try {
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('authKey', data.authKey);
      await dispatch(
        login({
          id: data?.id,
          firstName: data?.firstName,
          lastName: data?.lastName,
          companyId: data?.companyId,
          email: data?.email,
          authKey: data?.authKey,
          status: data?.systemRole.name,
          token: data?.accessToken,
          avatar: data?.avatar,
          systemPermissions: data?.systemRole,
          settings: data?.settings,
          isTwoFactorAuthenticationEnabled:
            data?.isTwoFactorAuthenticationEnabled,
        })
      );
      callback();
    } catch (error) {
      console.log('Error: ', error.message);
      console.log('Error: ', error);
    }
  };
};

export const thunkUserUpdate = (data, callback) => {
  return async (dispatch) => {
    try {
      // const token = localStorage.getItem('accessToken');
      await dispatch(
        login({
          id: data?.id,
          firstName: data?.firstName,
          lastName: data?.lastName,
          companyId: data?.companyId,
          email: data?.email,
          authKey: data?.authKey,
          status: data?.status,
          token: data?.token,
          avatar: data?.avatar,
          systemPermissions: data?.systemPermissions,
          settings: data?.settings,
          isTwoFactorAuthenticationEnabled:
            data?.isTwoFactorAuthenticationEnabled,
        })
      );
      callback();
    } catch (error) {
      console.log('Error: ', error.message);
      console.log('Error: ', error);
    }
  };
};

export const thunkLogout = (callback) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem('accessToken');
      await dispatch(logout());
    } catch (error) {
      console.log('Logout Error: ', error);
    }
  };
};
