const LOGIN = 'LOGIN';
const FETCH_USER = 'FETCH_USER';
const LOGOUT = 'LOGOUT';
const PERMISSIONS_UPDATED = 'PERMISSIONS_UPDATED';
const USER_UPDATED = 'USER_UPDATED';
const PROFILE_UPDATED = 'PROFILE_UPDATED';
const COUNT_REQUESTS = 'COUNT_REQUESTS';
const GET_MANAGERS_LIST = 'GET_MANAGERS_LIST';
const REMOVE_PROFILE_PICTURE = 'REMOVE_PROFILE_PICTURE';

export {
  LOGIN,
  FETCH_USER,
  LOGOUT,
  PERMISSIONS_UPDATED,
  USER_UPDATED,
  PROFILE_UPDATED,
  COUNT_REQUESTS,
  GET_MANAGERS_LIST,
  REMOVE_PROFILE_PICTURE,
};
