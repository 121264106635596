import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Permission = Array<string>;
function usePermissions() {
  const userData = useSelector((state: any) => state.user);

  const havePermission = (value: string): boolean => {
    return userData?.systemPermissions.permission?.includes(value);
  };
  return { havePermission };
}

export default usePermissions;
