import React from 'react';

function LinkedIn() {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.70721e-07 2.82505C2.70721e-07 2.33998 0.192693 1.87478 0.535688 1.53178C0.878684 1.18879 1.34388 0.996096 1.82895 0.996096H20.0608C20.3012 0.995704 20.5393 1.04273 20.7614 1.13448C20.9836 1.22623 21.1855 1.3609 21.3556 1.53079C21.5257 1.70067 21.6606 1.90244 21.7526 2.12452C21.8446 2.34661 21.8918 2.58466 21.8917 2.82505V21.0569C21.892 21.2973 21.8448 21.5354 21.753 21.7576C21.6611 21.9798 21.5263 22.1817 21.3564 22.3518C21.1864 22.5218 20.9846 22.6567 20.7624 22.7487C20.5403 22.8407 20.3022 22.8879 20.0618 22.8878H1.82895C1.58869 22.8878 1.35078 22.8405 1.12881 22.7485C0.90685 22.6565 0.705183 22.5217 0.535336 22.3518C0.365489 22.1818 0.23079 21.9801 0.138935 21.7581C0.0470804 21.5361 -0.00013045 21.2981 2.70721e-07 21.0579V2.82505ZM8.66514 9.34281H11.6295V10.8314C12.0574 9.97568 13.1519 9.20549 14.7968 9.20549C17.9502 9.20549 18.6975 10.9101 18.6975 14.0376V19.8309H15.5063V14.7501C15.5063 12.9689 15.0784 11.9638 13.9918 11.9638C12.4842 11.9638 11.8573 13.0475 11.8573 14.7501V19.8309H8.66514V9.34281ZM3.19221 19.6946H6.38442V9.20549H3.19221V19.6936V19.6946ZM6.84116 5.78441C6.84718 6.05773 6.79854 6.3295 6.69811 6.58377C6.59767 6.83804 6.44746 7.06969 6.25629 7.26512C6.06511 7.46055 5.83683 7.61582 5.58483 7.72183C5.33283 7.82784 5.0622 7.88245 4.78881 7.88245C4.51543 7.88245 4.24479 7.82784 3.9928 7.72183C3.7408 7.61582 3.51251 7.46055 3.32134 7.26512C3.13017 7.06969 2.97995 6.83804 2.87952 6.58377C2.77908 6.3295 2.73045 6.05773 2.73646 5.78441C2.74828 5.24792 2.9697 4.73738 3.3533 4.36213C3.7369 3.98688 4.25219 3.77676 4.78881 3.77676C5.32543 3.77676 5.84072 3.98688 6.22433 4.36213C6.60793 4.73738 6.82935 5.24792 6.84116 5.78441Z'
        fill='white'
      />
    </svg>
  );
}

export default LinkedIn;
