import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import FooterLogoPng from '../../../../src/assets/footer-logo.png';

export default function FooterLogo(props) {
  return (
    <Box {...props}>
      <Image src={FooterLogoPng} width={'100%'} />
    </Box>
  );
}
