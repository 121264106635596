import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
// @ts-ignore
import { routes } from '../../routes/index.ts';
import AuthLayout from '../components/layouts/AuthLayout';
import { Loader } from '../../commons/Loader';

const DashboardLayout = () => {
  return (
    <AuthLayout>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes &&
            routes.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={<route.component />}
              />
            ))}
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default DashboardLayout;
