import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
// @ts-ignore
import { routes } from '../../routes/index.ts';

import Layout from '../components/layouts/Layout';
import { Loader } from '../../commons/Loader';

const LandingPage = () => {
  return (
    <Layout
      style={{
        background: '#f4f8fc',
      }}
    >
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes &&
            routes.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={<route.component />}
              />
            ))}
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default LandingPage;
