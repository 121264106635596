import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import Logo from '../ui/Logo';
import backgroundImage from './Vector.png';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  SimpleGrid,
  VStack,
  Container,
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useMediaQuery } from '@chakra-ui/react';
const Links = [
  { id: 1, name: 'Home', path: '/' },
  { id: 2, name: 'About us', path: '/about-us/' },
  { id: 3, name: 'Features', path: '/feature/' },
  { id: 4, name: 'Blogs', path: '/blog/' },
  { id: 5, name: 'Pricing', path: '/pricing/' },
];

const Header = (props) => {
  const [sm, md, lg] = useMediaQuery([
    '(min-width: 420px)',
    '(min-width: 820px)',
    '(min-width: 992px)',
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      {/* <div> */}
      <div
        style={{
          zIndex: '10',
          position: 'fixed',
          width: '100%',
        }}
      >
        <Box
          px={4}
          colorScheme='primary'
          color='#253053'
          variant='solid'
          background={'white'}
          top={0}
          left={0}
          right={0}
          height='75px'
          borderBottom='2px solid rgba(0, 0, 0, 0.15)'
          position={'absolute'}
        >
          <Stack direction={['row']} mt='12px' justify='space-between'>
            <HStack alignItems={'center'} justifyContent='center'>
              <Logo
                ml={['100px']}
                w='170px'
                color={['white', 'white', 'primary.500', 'primary.500']}
              />
            </HStack>
            <HStack>
              <IconButton
                size={'md'}
                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                aria-label={'Open Menu'}
                display={{ lg: 'none' }}
                onClick={isOpen ? onClose : onOpen}
                color={'white'}
                bg='#253053'
                _hover={{
                  bg: '#134b5f',
                }}
              />
            </HStack>
            {lg ? (
              <HStack
                w='100%'
                justify='flex-end'
                mr='100px'
                align='center'
                spacing={8}
              >
                {Links.map((link) => (
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      isActive ? 'header-active-class' : ''
                    }
                  >
                    <p>{link.name}</p>
                  </NavLink>
                ))}
                <Button
                  colorScheme='#253053'
                  variant='outline'
                  onClick={() => navigate('/signin/')}
                  _hover={{ bg: '#2C82D1', color: 'white' }}
                >
                  Sign In
                </Button>
                <Button
                  width='148.59px'
                  height='43.65px'
                  size='sm'
                  rounded='md'
                  color={['primary.500', 'primary.500', 'white', 'white']}
                  bg='#253053'
                  _hover={{
                    bg: '#2C82D1',
                  }}
                  onClick={() => navigate('/signup/')}
                >
                  Create Account
                </Button>
              </HStack>
            ) : null}
          </Stack>
          <Container maxW='800px'>
            {isOpen ? (
              <Box
                pb={4}
                bg='white'
                position='relative'
                zIndex='111'
                pl={5}
                pr={5}
                display={{ lg: 'none' }}
                pt={1}
              >
                <Stack justifyContent='space-between'>
                  {Links.map((link) => (
                    <NavLink
                      to={link.path}
                      className={({ isActive }) =>
                        isActive ? 'header-active-class' : ''
                      }
                    >
                      {link.name}
                    </NavLink>
                  ))}
                  <Button
                    colorScheme='#253053'
                    variant='outline'
                    onClick={() => navigate('/signin/')}
                    _hover={{ bg: '#2C82D1', color: 'white' }}
                  >
                    Sign In
                  </Button>
                  <Button
                    size='sm'
                    rounded='md'
                    color={'white'}
                    bg='#253053'
                    _hover={{
                      bg: '#134b5f',
                    }}
                    onClick={() => navigate('/signup/')}
                  >
                    Create Account
                  </Button>
                </Stack>
              </Box>
            ) : null}
          </Container>
        </Box>
      </div>
      {/* </div> */}
    </>
  );
};

export default Header;
