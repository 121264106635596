import React from "react";
import { Box, Image } from "@chakra-ui/react";
// import invodeskLogo from '../../../../src/assets/invodesk-white-logo.png';
import invodeskLogo from "../../../../src/assets/New-logo.svg";
import { useNavigate } from "react-router-dom";

const Logo = (props) => {
  const navigate = useNavigate();

  return (
    <Box {...props}>
      <Image
        src={invodeskLogo}
        width={"100%"}
        cursor="pointer"
        onClick={() => navigate("/")}
      />
    </Box>
  );
};

export default Logo;
