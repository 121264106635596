// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Roboto Slab';
  }
  .react-datepicker-popper {
  z-index: 1000 !important;
  }
  .react-datepicker__input-container{
  margin-top: 10px;
  }
  .input-with-focus-visible{
  outline: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0 !important;
  }
  .react-datepicker__header{
  background: #2e73ba !important;
  color: white !important;
  }
  .react-datepicker__triangle::after{
  border-bottom-color: #2e73ba !important;
  }
  .react-datepicker{
  border: 1px solid #2e73ba !important;
  }
  .react-datepicker__navigation-icon::before{
  border-color: #ffffff !important;
  }
  .react-datepicker__month-text--keyboard-selected:hover{
  color: #ffff !important;
  background: #2e73ba !important;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B;EACA;EACA,wBAAwB;EACxB;EACA;EACA,gBAAgB;EAChB;EACA;EACA,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB;EACA;EACA,8BAA8B;EAC9B,uBAAuB;EACvB;EACA;EACA,uCAAuC;EACvC;EACA;EACA,oCAAoC;EACpC;EACA;EACA,gCAAgC;EAChC;EACA;EACA,uBAAuB;EACvB,8BAA8B;EAC9B","sourcesContent":["body {\n  font-family: 'Roboto Slab';\n  }\n  .react-datepicker-popper {\n  z-index: 1000 !important;\n  }\n  .react-datepicker__input-container{\n  margin-top: 10px;\n  }\n  .input-with-focus-visible{\n  outline: none;\n  font-size: 18px;\n  font-weight: 600;\n  padding: 0 !important;\n  }\n  .react-datepicker__header{\n  background: #2e73ba !important;\n  color: white !important;\n  }\n  .react-datepicker__triangle::after{\n  border-bottom-color: #2e73ba !important;\n  }\n  .react-datepicker{\n  border: 1px solid #2e73ba !important;\n  }\n  .react-datepicker__navigation-icon::before{\n  border-color: #ffffff !important;\n  }\n  .react-datepicker__month-text--keyboard-selected:hover{\n  color: #ffff !important;\n  background: #2e73ba !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
