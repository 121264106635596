import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import LandingPage from './landing/pages';
import DashboardLayout from './dashboard/pages';
import './index.css';
import './assets/css/global.css';

const App: FC = () => {
  const userData = useSelector((state: any) => state.user);

  return <>{!userData.token ? <LandingPage /> : <DashboardLayout />}</>;
};

export default App;
