import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import invodeskLogo from '../../../../src/assets/New-logo.svg';
import { useNavigate } from 'react-router-dom';

export default function Logo(props) {
  const navigate = useNavigate();

  return (
    <Box {...props}>
      <Image
        src={invodeskLogo}
        width={'100%'}
        cursor='pointer'
        onClick={() => navigate('/')}
      />
    </Box>
  );
}
