import { Tag, TagLabel } from '@chakra-ui/react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

export const capitilizeFirstLetter = (str) => {
  let name = str;
  return name?.replace(/\b\w/g, function (c) {
    return c.toUpperCase();
  });
};

export const onCopyInput = (e) => {
  e.preventDefault();
  return false;
};

export const onPasteInput = (e) => {
  e.preventDefault();
  return false;
};

export const fomatedDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const minutesToHours = (minutes) => {
  const time = moment
    .utc(moment.duration(minutes, 'minutes').asMilliseconds())
    .format('HH:mm');
  return time;
};

export const getStartAndEndOfWeek = (dateValue) => {
  const date = moment(dateValue);
  const dayOfWeek = date.day();
  const diff = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const startOfWeek = moment(date).subtract(diff, 'days');
  const endOfWeek = moment(startOfWeek).add(6, 'days');
  return { startOfWeek, endOfWeek };
};
export const TagData = ({ data }) => {
  return (
    <>
      {data === 'Pending' ? (
        <Tag size='sm' colorScheme='orange' borderRadius='full'>
          <TagLabel>{data}</TagLabel>
        </Tag>
      ) : (
        <Tag size='sm' colorScheme='green' borderRadius='full'>
          <TagLabel>{data}</TagLabel>
        </Tag>
      )}
    </>
  );
};

export const OtherTagData = ({ data }) => {
  return (
    <>
      {data === 'APPROVEDBYHR' ? (
        <Tag size='sm' colorScheme='green' borderRadius='full'>
          <TagLabel>Approved</TagLabel>
        </Tag>
      ) : data === 'MANUALAPPROVEDBYHR' ? (
        <Tag size='sm' colorScheme='gray' borderRadius='full'>
          <TagLabel>Manually Approved</TagLabel>
        </Tag>
      ) : (
        <Tag size='sm' colorScheme='red' borderRadius='full'>
          <TagLabel>Rejected</TagLabel>
        </Tag>
      )}
    </>
  );
};

export const getQueryVariable = (location) => {
  return new URLSearchParams(location?.search);
};

export const getTimeDifferenceInHoursAndMinutes = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const diff = (end.getTime() - start.getTime()) / 1000;

  const totalMinutes = Math.abs(Math.round(diff / 60));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

export const getLastWeekStartAndEndDate = () => {
  // get today's date
  const today = moment();

  // get current week's start date
  const currentWeekStart = today.clone().startOf('week').format('YYYY-MM-DD');

  // get current week's end date
  const currentWeekEnd = today.clone().endOf('week').format('YYYY-MM-DD');

  return {
    currentWeekStart,
    currentWeekEnd,
  };
};

export const IP_ADDRESSES = [
  '103.73.101.34',
  '182.180.151.38',
  '59.103.120.86',
  '103.170.179.168',
  '182.180.107.15',
];
export const AvatarUrl = (avatar) => {
  if (typeof avatar === 'string' && avatar.trim() !== '') {
    // Check if the string looks like a URL
    if (/^https?:\/\//i.test(avatar)) {
      const url = new URL(avatar);

      // Check if the avatar URL starts with the s3 URLs
      if (
        url.origin === 'https://invodesk-prod.s3.us-east-2.amazonaws.com' ||
        url.origin === 'https://invodesk-qa.s3.us-east-2.amazonaws.com' ||
        url.origin === 'https://invodesk-dev.s3.amazonaws.com' ||
        url.origin === 'https://invodesk-prod.s3.amazonaws.com'
      ) {
        return avatar; // Return avatar URL as is
      }
    } else {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const baseUrlSegments = new URL(baseUrl);
      const finalUrl = baseUrlSegments.origin;
      return `${finalUrl}/${avatar}`;
    }
  }
  // If avatar is falsy or not a valid URL string, handle it accordingly
  return null; // Or some default value depending on your use case
};
