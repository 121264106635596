import { gql } from '@apollo/client';

export const OFFLINE_TIME_REQUESTS = gql`
  query offlineTimeRequests($where: OfflineSlotsWhereInput!) {
    offlineTimeRequests(where: $where) {
      id
      offlineStartTime
      offlineEndTime
      status
      offlineTime
      offlineReason
      rejectionReason

      reportingManager
      user {
        firstName
        lastName
        email
      }
      hr {
        firstName
        lastName
        email
      }
      manager {
        firstName
        lastName
        email
      }
    }
  }
`;

export const OFFLINE_TIME_REQUESTS_APPROVED_BY_PM = gql`
  query approvedByPmOfflineTimeRequests {
    approvedByPmOfflineTimeRequests {
      id
      offlineTime
      userId
      isApproved
      approvedBy
      user {
        firstName
        lastName
      }
    }
  }
`;
