import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
//@ts-ignore
import { OFFLINE_TIME_REQUESTS } from '../../graphqlApis/queries/ManualTimeQueries.ts';
//@ts-ignore
import { GET_PM_LISTING } from '../../graphqlApis/mutations/ManualTimeMutation.ts';
// @ts-ignore
import usePermissions from '../pages/projects/customhooks/usePermissions.tsx';
// @ts-ignore
import {
  countRequestsUpdated,
  getManagersList,
} from '../../redux/action/user.action.ts';
import { fomatedDate } from '../../utils/index';
import { capitilizeFirstLetter } from '../../utils/index';
function useLoggedRequests() {
  const [allRequests, setAllRequests] = useState([]);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { havePermission } = usePermissions();

  const commonQuery = {
    companyId: {
      equals: user.companyId,
    },
    status: {
      equals: 'PENDING',
    },
  };
  const variablesForLoggedUser = () => {
    if (user.status === 'project-manager' || user.status === 'manager') {
      return {
        ...commonQuery,
        reportingManager: {
          equals: user.id,
        },
      };
    }
    if (havePermission('canApproveManualTime')) {
      return {
        ...commonQuery,
        // reportingManager: {
        //   equals: user.id,
        // },
      };
    }
    if (havePermission('canFinalApproveManualTime')) {
      return {
        ...commonQuery,
      };
    }
    return {
      userId: {
        equals: user.id,
      },
      ...commonQuery,
    };
  };

  const [
    getProjectManagers,
    {
      loading: managersDataLoading,
      data: projectManagersData,
      error: pmErrors,
    },
  ] = useMutation(GET_PM_LISTING, { fetchPolicy: 'network-only' });

  const { loading, data, error } = useQuery(OFFLINE_TIME_REQUESTS, {
    variables: {
      where: variablesForLoggedUser(),
    },
  });

  useEffect(() => {
    const requestsData = data?.offlineTimeRequests.map((el) => {
      return {
        id: el.id,
        offlineTime: el?.offlineTime,
        isApproved: capitilizeFirstLetter(el?.status.toLowerCase()),
        name: `${el?.user?.firstName} ${el?.user?.lastName}`,
        reason: el?.offlineReason,
        manager: el.manager.email,
        user: `${el?.user?.firstName} ${el?.user?.lastName}`,
        date: fomatedDate(el.offlineStartTime),
      };
    });
    setAllRequests(requestsData);
    dispatch(countRequestsUpdated(data?.offlineTimeRequests.length));
  }, [data]);

  useEffect(() => {
    getProjectManagers();
  }, []);

  useEffect(() => {
    dispatch(getManagersList(projectManagersData?.getProjectManagers));
  }, [projectManagersData]);

  return {
    loading,
    error,
    allRequests,
    countRequests: allRequests?.length,
  };
}

export default useLoggedRequests;
