import { lazy } from 'react';

// @ts-ignore
const About = lazy(() => import('../landing/pages/AboutUs.tsx'));
// @ts-ignore
const Contact = lazy(() => import('../landing/pages/Contact.tsx'));
// @ts-ignore
const Faq = lazy(() => import('../landing/pages/Faq.tsx'));
// @ts-ignore
const Blog = lazy(() => import('../landing/pages/blog/Blog.tsx'));
// @ts-ignore
const Detail = lazy(() => import('../landing/pages/blog/Detail.tsx'));
// @ts-ignore
const Login = lazy(() => import('../landing/pages/auth/Login.tsx'));
// @ts-ignore
const Register = lazy(() => import('../landing/pages/auth/Register.tsx'));
// @ts-ignore
const AccountVerification = lazy(
  // @ts-ignore
  () => import('../landing/pages/auth/AccountVerification.tsx')
);
// @ts-ignore
const ForgetPassword = lazy(
  // @ts-ignore
  () => import('../landing/pages/auth/ForgetPassword.tsx')
);
// @ts-ignore
const ResetPassword = lazy(
  // @ts-ignore
  () => import('../landing/pages/auth/ResetPassword.tsx')
);
// @ts-ignore
const NotFound = lazy(() => import('../landing/pages/NotFound.tsx'));
// @ts-ignore
const Pricing = lazy(() => import('../landing/pages/Pricing.tsx'));
// @ts-ignore
const Features = lazy(() => import('../landing/pages/Features.tsx'));
// @ts-ignore
const Download = lazy(() => import('../landing/pages/Downloads.tsx'));
//@ts-ignore
const Settings = lazy(() => import('../dashboard/pages/settings/Settings.tsx'));
const PasswordSetting = lazy(
  //@ts-ignore
  () => import('../dashboard/pages/settings/PasswordSetting.tsx')
);
// @ts-ignore
const Dashboard = lazy(() => import('../dashboard/pages/Dashboard.tsx'));

const ProjectDetail = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/projectDetail/Index.tsx')
);
// @ts-ignore
const Profile = lazy(() => import('../dashboard/pages/profile/Profile.tsx'));
// @ts-ignore
const Plans = lazy(() => import('../dashboard/pages/billings/Plans.tsx'));
// @ts-ignore
const Summary = lazy(() => import('../dashboard/pages/summary/Summary.tsx'));
// @ts-ignore
const TimeSheet = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/timesheet/TimeSheet.tsx')
);
// @ts-ignore
const Project = lazy(() => import('../dashboard/pages/projects/Project.tsx'));
// @ts-ignore
const Search = lazy(() => import('../dashboard/pages/projects/Search.tsx'));
// @ts-ignore
const Tasks = lazy(() => import('../dashboard/pages/tasks/Tasks.tsx'));

const ManageUsers = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/userManagement/ManageUsers.tsx')
);
// @ts-ignore
const InviteUser = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/invitesUser/InviteUser.tsx')
);
// @ts-ignore
const AcceptInvite = lazy(
  // @ts-ignore
  () => import('../landing/pages/auth/AcceptInvite.tsx')
);

const RoleManagement = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/rolesManagement/RoleManagement.tsx')
);

const Activities = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/activities/Screenshots.tsx')
);

const Companies = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/companies/ManageCompanies.tsx')
);
const ManagePermissions = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/permissions/ManagePermissions.tsx')
);
// @ts-ignore
const reports = lazy(() => import('../dashboard/pages/reports/Reports.tsx'));
const Home = lazy(() => import('../landing/pages/Home.tsx'));

const ActivityandSummary = lazy(
  () =>
    import(
      // @ts-ignore
      '../dashboard/pages/reports/components/ActivitiesAndSummries/ActivityandSummary.tsx'
    )
);
const ManualTimeEdits = lazy(
  () =>
    import(
      // @ts-ignore
      '../dashboard/pages/reports/components/ManualTimeEdits/ManualTimeEdits.tsx'
    )
);

const WorkSessions = lazy(
  () =>
    import(
      // @ts-ignore
      '../dashboard/pages/reports/components/WorkSessions/WorkSessions.tsx'
    )
);
const HoursTracked = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/reports/components/HoursTracked.tsx')
);
const ProjectsandTask = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/reports/components/ProjectsandTask.tsx')
);
const WeeklyReport = lazy(
  () =>
    import(
      // @ts-ignore
      '../dashboard/pages/reports/components/weeklyReports/WeeklyReport.tsx'
    )
);

const AssetsAndLocations = lazy(
  () =>
    import(
      // @ts-ignore
      '../dashboard/pages/reports/components/assetsAndLocations/AssetsAndLocations.tsx'
    )
);

const AppsUsage = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/reports/components/AppsUsage.tsx')
);

const ManageManualTimeRequests = lazy(
  // @ts-ignore
  () =>
    import('../dashboard/pages/manualTimeRequests/ManageManualTimeRequests.tsx')
);

const IpAddress = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/ipAddresses/IpAddress.tsx')
);

const MacAddress = lazy(
  // @ts-ignore
  () => import('../dashboard/pages/macAddresses/MacAddress.tsx')
);

export const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
  },
  {
    name: 'Login',
    path: '/signin/',
    component: Login,
  },
  {
    name: 'Register',
    path: '/signup/',
    component: Register,
  },
  {
    name: 'AccountVerification',
    path: '/activate/:activationToken',
    component: AccountVerification,
  },
  {
    name: 'Forget Password',
    path: '/forget-password',
    component: ForgetPassword,
  },
  {
    name: 'Reset Password',
    path: '/reset-password/:token',
    component: ResetPassword,
  },
  {
    name: 'About',
    path: '/about-us/',
    component: About,
  },
  {
    name: 'Contact',
    path: '/contact',
    component: Contact,
  },
  {
    name: 'Faq',
    path: '/faq',
    component: Faq,
  },
  {
    name: 'Pricing',
    path: '/pricing',
    component: Pricing,
  },
  {
    name: 'Features',
    path: '/feature/',
    component: Features,
  },
  {
    name: 'Download',
    path: '/downloads/',
    component: Download,
  },
  {
    name: 'Blog',
    path: '/blog',
    component: Blog,
  },
  {
    name: 'Detail',
    path: '/detail/:id',
    component: Detail,
  },
  {
    name: 'Not Found',
    path: '*',
    component: NotFound,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
  },
  {
    name: 'Profile',
    path: '/profile',
    component: Profile,
  },
  {
    name: 'Not Found',
    path: '*',
    component: NotFound,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
  },
  {
    name: 'Profile',
    path: '/profile',
    component: Profile,
  },
  {
    name: 'Plans',
    path: '/plans',
    component: Plans,
  },
  {
    name: 'Settings',
    path: '/settings',
    component: Settings,
  },
  {
    name: 'Summary',
    path: '/summary',
    component: Summary,
  },
  {
    name: 'TimeSheet',
    path: '/timesheets',
    component: TimeSheet,
  },
  {
    name: 'Project',
    path: '/projects',
    component: Project,
  },
  {
    name: 'ProjectDetail',
    path: '/projectDetail',
    component: ProjectDetail,
  },
  {
    name: 'Search',
    path: '/search',
    component: Search,
  },
  {
    name: 'Tasks',
    path: '/tasks',
    component: Tasks,
  },
  {
    name: 'Companies',
    path: '/companies',
    component: Companies,
  },
  {
    name: 'UserManagement',
    path: '/user-management',
    component: ManageUsers,
  },
  {
    name: 'InviteUser',
    path: '/invite-users',
    component: InviteUser,
  },
  {
    name: 'AcceptInvite',
    path: '/accept-invite/:email',
    component: AcceptInvite,
  },
  {
    name: 'Activities',
    path: '/activities',
    component: Activities,
  },
  {
    name: 'RoleManagement',
    path: '/roles-management',
    component: RoleManagement,
  },
  {
    name: 'ManagePermissions',
    path: '/permissions-management',
    component: ManagePermissions,
  },
  {
    name: 'Reports',
    path: '/reports',
    component: reports,
  },
  {
    name: 'ActivityandSummary',
    path: '/activities-and-summaries',
    component: ActivityandSummary,
  },
  {
    name: 'ManualTimeEdits',
    path: 'manual-time-edits',
    component: ManualTimeEdits,
  },
  {
    name: 'HoursTracked',
    path: '/hours-tracked',
    component: HoursTracked,
  },
  {
    name: 'WorkSessions',
    path: '/work-sessions',
    component: WorkSessions,
  },
  {
    name: 'ProjectsandTask',
    path: '/projects-and-tasks',
    component: ProjectsandTask,
  },
  {
    name: 'WeeklyReport',
    path: '/weekly-reports',
    component: WeeklyReport,
  },
  {
    name: '  AssetsAndLocations',
    path: '/assets-and-locations',
    component: AssetsAndLocations,
  },
  {
    name: 'AppsUsage',
    path: '/apps-usage',
    component: AppsUsage,
  },
  {
    name: 'ManageManualTimeRequests',
    path: '/manual-time-requests',
    component: ManageManualTimeRequests,
  },
  {
    name: 'IpAddress',
    path: '/ip-addresses',
    component: IpAddress,
  },
  {
    name: 'MacAddress',
    path: '/mac-addresses',
    component: MacAddress,
  },
];
