import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import classNames from 'classnames';
import {
  Box,
  Text,
  Stack,
  chakra,
  Container,
  SimpleGrid,
  VisuallyHidden,
  useColorModeValue,
  Flex,
  HStack,
  Input,
  Button,
  Spinner,
  useToast,
  FormControl,
  FormLabel,
  VStack,
  Heading,
} from '@chakra-ui/react';
import FacebookIcon from '../ui/icons/FacebookIcon';
import LinkedIn from '../ui/icons/LinkedIn';
import Twitter from '../ui/icons/Twitter';
import InvodeskLogo from '../../../../src/assets/New-logo.svg';
import logoWhite from '../../../../src/assets/logo-white.png';
import FooterLogo from '../../../dashboard/components/ui/FooterLogo';
import image03 from './03.png';
import Youtube from '../ui/icons/Youtube';
import { useLocation } from 'react-router-dom';
// @ts-node
import { SEND_NEWS_LETTER } from '../../../graphqlApis/queries/userQueries.ts';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Footer = () => {
  const [email, setEmail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const toast = useToast();

  const [newsLetter, { data, loading, error }] = useMutation(SEND_NEWS_LETTER);

  useEffect(() => {
    if (data) {
      toast({
        title: 'Success',
        description: `News letter subscribed successfully.`,
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        description: error?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }, [error]);

  return (
    <>
      {!['/register', '/login'].includes(location.pathname) ? (
        <div
          style={{
            backgroundImage: `url(${image03})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100vw 233px',
            width: '100vw',
            height: '233px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {location.pathname === '/about-us' ? (
            <VStack>
              <HStack spacing='0'>
                <Input
                  borderRadius='0px'
                  borderTopLeftRadius='5px'
                  borderBottomLeftRadius='5px'
                  w={['50vw', '50vw', '25vw']}
                  type='email'
                  h='50px'
                  bg='white'
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Enter your email'
                  borderRadiusRight='0'
                />
                <Link to={`/register?email=${email}`}>
                  <Button
                    w={['40vw', '40vw', '40vw', '20vw']}
                    borderRadius='0px'
                    borderTopRightRadius='5px'
                    textDecoration='none'
                    borderBottomRightRadius='5px'
                    h='50px'
                    bg='#253053'
                    color='white'
                    fontWeight='600'
                    fontSize={['3vw', '3vw', '3vw', '1.5vw']}
                    lineHeigh='35px'
                    _hover={{ bg: '#2C82D1' }}
                    onClick={() => navigate('/signup/')}
                  >
                    {' '}
                    Launch a free trial
                  </Button>
                </Link>
              </HStack>
              <Box color='white'>
                Free 14-day trial. No credit card is required.
              </Box>
              <Box color='white'>
                By signing up, you agree to our terms and privacy policy.
              </Box>
            </VStack>
          ) : (
            <VStack>
              <Heading fontSize={['5vw', '3vw']} className='image-txt' as='h2'>
                Try InvoDesk free for 14 days
              </Heading>
              <Box mt={8}></Box>
              <Button
                w={['20vw']}
                h={['40px', '40px', '56px']}
                bg='#253053'
                color='white'
                fontWeight='600'
                fontSize={['3vw', '3vw', '3vw', '2vw']}
                lineHeigh='35px'
                _hover={{ bg: '#2C82D1' }}
                onClick={() => navigate('/signup/')}
              >
                <p> Sign up</p>
              </Button>
            </VStack>
          )}
        </div>
      ) : null}

      <Box bg={'#253053'} color='white'>
        <Container as={Stack} maxW={'8xl'} py={10} w='80%'>
          <Flex justify='flex-start' ml='-10px'>
            <div style={{ width: '200px', height: '100px' }}>
              <img src={logoWhite} alt='invodesk-logo' />
            </div>
          </Flex>
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 4 }}
            spacing={8}
            zIndex={'1'}
          >
            <Stack align={'flex-start'}>
              <ListHeader>Company</ListHeader>
              <Link to='/about-us/' cursor='pointer'>
                How It Works
              </Link>
              <Link to='/blog/'>For Your Team</Link>
              <Link to='/downloads/'>Download</Link>
            </Stack>

            <Stack align={'flex-start'}>
              <ListHeader>Support</ListHeader>
              <Link to='/'>Help</Link>
              <Link to='/faq/'>FAQ & Best Practices</Link>
              <Link to='/'>Security</Link>
              <Link to='/contact/'>Contact Us</Link>
              <Link to='/'>Terms</Link>
            </Stack>

            <Stack align={'flex-start'}>
              <ListHeader>Resources</ListHeader>
              <Link to='/blog/'>Blog </Link>
              <Link to='/'>Customer Stories</Link>
            </Stack>
            <Stack spacing={8}>
              <HStack spacing='20px'>
                <a
                  rel='noreferrer'
                  href='https://www.facebook.com/invodeskofficial/'
                  target='_blank'
                >
                  <FacebookIcon />
                </a>
                <a
                  rel='noreferrer'
                  href='https://www.linkedin.com/company/invodesk/'
                  target='_blank'
                >
                  <LinkedIn />
                </a>
                <a
                  rel='noreferrer'
                  href='https://twitter.com/invo_desk/'
                  target='_blank'
                >
                  <Twitter />
                </a>
                <Link to='/'>
                  <Youtube />
                </Link>
              </HStack>

              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Email is invalid')
                    .max(255)
                    .required('Email is required'),
                })}
                onSubmit={(values, { resetForm }) => {
                  newsLetter({
                    variables: {
                      input: values?.email,
                    },
                  });
                  resetForm({ values: '' });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit} noValidate autoComplete='off'>
                    <FormControl>
                      <FormLabel htmlFor='email'>Email</FormLabel>
                      <Input
                        id='email-address'
                        name='email'
                        type='email'
                        variant='flushed'
                        className={classNames([
                          errors.email && 'border-2 border-rose-600',
                        ])}
                        placeholder='Email address'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <Text color='red' size={'small'} mt='1'>
                        {errors.email && touched.email ? (
                          <>{errors.email}</>
                        ) : null}
                      </Text>
                    </FormControl>

                    <Stack className='z-inde' spacing='6' mt={'4'} mb='2'>
                      <Button
                        w='178px'
                        h='49px'
                        fontWeight=' 600'
                        fontSize='20px'
                        lineHeight='23px'
                        _hover={{ bg: '#24D18E' }}
                        bg='#2C82D1'
                        type='submit'
                        disabled={loading}
                      >
                        Subscribe &nbsp;
                        {loading && <Spinner ml={'4'} color='white.500' />}
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </SimpleGrid>
        </Container>

        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          colorScheme='primary'
          bgGradient='#253053'
          color='white'
          variant='solid'
        >
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ md: 'space-between' }}
            align={{ md: 'center' }}
          >
            <Text color='white'>
              © {new Date().getFullYear()} Invodesk. All rights reserved
            </Text>
            <Stack direction={'row'} spacing={6}>
              <Box w='200px'>
                <FooterLogo />
              </Box>
            </Stack>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
