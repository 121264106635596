/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import { useMediaQuery } from "@chakra-ui/react";

const Layout = (props) => {
  const [sm] = useMediaQuery("(min-width: 420px)");
  const [auth, isAuth] = useState(false);
  return (
    <Flex direction="column" {...props}>
      <div style={{ marginBottom: "30px" }}>
        <Header />
      </div>
      <Box p={4} pl={["10px", "100px"]} pr={["10px", "100px"]}>
        {props.children}
      </Box>
      <Footer />
    </Flex>
  );
};
export default Layout;
