import React from "react";

function Twitter() {
  return (
    <svg
      width='26'
      height='23'
      viewBox='0 0 26 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26 3.38109C25.0439 3.82207 24.0167 4.12004 22.9369 4.25472C24.0511 3.56081 24.8846 2.46868 25.282 1.1821C24.2353 1.82927 23.0896 2.28481 21.8949 2.52891C21.0915 1.63604 20.0274 1.04424 18.8677 0.845371C17.7081 0.646506 16.5178 0.851708 15.4816 1.42912C14.4455 2.00653 13.6215 2.92384 13.1376 4.03864C12.6537 5.15344 12.5369 6.40335 12.8053 7.59432C10.6843 7.48347 8.60937 6.90965 6.71519 5.9101C4.82101 4.91055 3.14991 3.50762 1.81036 1.79234C1.35233 2.61472 1.08896 3.56821 1.08896 4.58367C1.08845 5.49783 1.30473 6.39798 1.71861 7.20426C2.1325 8.01054 2.73118 8.69802 3.46155 9.20571C2.61452 9.17766 1.78617 8.93943 1.04545 8.51086V8.58237C1.04537 9.8645 1.47145 11.1072 2.25142 12.0995C3.03139 13.0919 4.11719 13.7728 5.32458 14.0268C4.53882 14.2481 3.715 14.2807 2.91535 14.1221C3.25601 15.2253 3.91958 16.19 4.81316 16.8812C5.70674 17.5724 6.7856 17.9554 7.89871 17.9766C6.00915 19.5206 3.67555 20.3581 1.27332 20.3544C0.847789 20.3545 0.422619 20.3286 0 20.2769C2.4384 21.9088 5.27688 22.7748 8.17581 22.7715C17.9891 22.7715 23.3537 14.3116 23.3537 6.97455C23.3537 6.73618 23.348 6.49542 23.3377 6.25705C24.3812 5.47158 25.2819 4.49893 25.9977 3.38466L26 3.38109Z'
        fill='white'
      />
    </svg>
  );
}

export default Twitter;
