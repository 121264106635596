import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  useColorMode,
} from '@chakra-ui/react';
import {
  FiHome,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi';
import { BsMoonFill, BsSun } from 'react-icons/bs';
import { capitilizeFirstLetter } from '../../../utils';
import { IconType } from 'react-icons';
// @ts-ignore
import BellButton from './BellButton.tsx';
// @ts-ignore
import { thunkLogout } from '../../../redux/action/user.action.ts';

import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
// @ts-ignore
import { GET_USER_PROFILE } from '../../../graphqlApis/queries/userQueries.ts';
import Logo from '../ui/Logo';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  AiOutlineUser,
  AiOutlineProject,
  AiOutlineClockCircle,
} from 'react-icons/ai';
import { BiDetail, BiTime, BiBuildings } from 'react-icons/bi';
import { BsListTask, BsArrowRightShort } from 'react-icons/bs';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { TiGroupOutline } from 'react-icons/ti';
import { FiActivity } from 'react-icons/fi';
// @ts-ignore
import useLoggedRequests from '../../hooks/useLoggedRequests.tsx';
// @ts-ignore
import usePermission from '../../pages/projects/customhooks/usePermissions.tsx';

import { Badge } from '@chakra-ui/react';
import { FaBell } from 'react-icons/fa';
import { AvatarUrl } from '../../../utils';
interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}

const ComapnyLinkItems: Array<LinkItemProps> = [
  { name: 'Home', path: '/dashboard', icon: FiHome },
  { name: 'Summary', path: '/summary', icon: BiDetail },
  { name: 'Activities', path: '/activities', icon: FiActivity },
  // { name: 'Projects', path: '/projects', icon: AiOutlineProject },
  {
    name: 'Manual Time',
    path: 'manual-time-requests',
    icon: AiOutlineClockCircle,
  },
  { name: 'Reports', path: '/reports', icon: HiOutlineDocumentReport },
  // { name: 'Tasks', path: '/tasks', icon: BsListTask },
  { name: 'My Companies', path: '/companies', icon: BiBuildings },
  { name: 'Users & Roles', path: '/user-management', icon: TiGroupOutline },

  { name: 'Settings', path: '/settings', icon: FiSettings },
];

const HRLinkItems: Array<LinkItemProps> = [
  { name: 'Home', path: '/dashboard', icon: FiHome },
  { name: 'Summary', path: '/summary', icon: BiDetail },
  // { name: 'Reports', path: '/reports', icon: HiOutlineDocumentReport },
  { name: 'Activities', path: '/activities', icon: FiActivity },
  {
    name: 'Manual Time',
    path: 'manual-time-requests',
    icon: AiOutlineClockCircle,
  },
  // { name: 'Reports', path: '/reports', icon: HiOutlineDocumentReport },
  { name: 'Users & Roles', path: '/user-management', icon: TiGroupOutline },
  { name: 'Settings', path: '/settings', icon: FiSettings },
];

const EmployeeLinkItems: Array<LinkItemProps> = [
  { name: 'Home', path: '/dashboard', icon: FiHome },
  { name: 'Summary', path: '/summary', icon: BiDetail },
  { name: 'Activities', path: '/activities', icon: FiActivity },
  {
    name: 'Manual Time',
    path: 'manual-time-requests',
    icon: AiOutlineClockCircle,
    component: <div>10</div>,
  },
  // { name: 'Reports', path: '/reports', icon: HiOutlineDocumentReport },
  // { name: 'Projects', path: '/projects', icon: AiOutlineProject },
  // { name: 'Tasks', path: '/tasks', icon: BsListTask },
  { name: 'Settings', path: '/settings', icon: FiSettings },
];

const menuStyles = {
  colorScheme: 'primary',
  bgGradient: 'linear(to-r, blue.400, blue.500, blue.600)',
  color: 'white',
  variant: 'solid',
};
interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const userData = useSelector((state: any) => state.user);
  const countRequests = useSelector((state: any) => state.user.countRequests);
  const navigate = useNavigate();

  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <Flex h='20' alignItems='center' justifyContent='space-between' mb='4'>
        <Text
          mx='8'
          fontSize='2xl'
          fontFamily='monospace'
          fontWeight='bold'
          onClick={() => navigate('/dashboard')}
        >
          <Logo
            w='150px'
            color={['white', 'white', 'primary.500', 'primary.500']}
          />
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {userData?.status === 'company'
        ? ComapnyLinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} path={link.path}>
              <HStack>
                <Box> {link.name}</Box>
              </HStack>
            </NavItem>
          ))
        : userData?.status === 'hr'
        ? HRLinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} path={link.path}>
              <HStack>
                <Box> {link.name}</Box>
              </HStack>
            </NavItem>
          ))
        : EmployeeLinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} path={link.path}>
              <HStack>
                <Box> {link.name}</Box>
              </HStack>
            </NavItem>
          ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  path: any;
  children: any;
}

const NavItem = ({ path, icon, children, ...rest }: NavItemProps) => {
  return (
    <>
      <NavLink
        to={path}
        className={({ isActive }) => (isActive ? 'active-class' : '')}
      >
        <Flex
          className='nav-active'
          align='center'
          p='2'
          mx='4'
          borderRadius='lg'
          role='group'
          cursor='pointer'
          _hover={{
            color: 'gray',
          }}
          {...rest}
        >
          {icon && <Icon mr='4' fontSize='16' as={icon} />}

          {children}
        </Flex>
      </NavLink>
    </>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const userData = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Custom Hook
  const { countRequests } = useLoggedRequests();
  //Custom hook
  const { havePermission } = usePermission();
  const signOut = () => {
    dispatch(thunkLogout());
    navigate('/signin/');
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize='2xl'
        fontFamily='monospace'
        fontWeight='bold'
        onClick={() => navigate('/dashboard')}
      >
        <Logo
          w='150px'
          color={['white', 'white', 'primary.500', 'primary.500']}
        />
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        {/* <Button onClick={toggleColorMode}>
          {colorMode === 'light' ? <BsMoonFill /> : <BsSun />}
        </Button> */}

        {havePermission('canApproveManualTime') ||
        havePermission('canFinalApproveManualTime') ? (
          <BellButton count={countRequests} />
        ) : null}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition='all 0.3s'
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar size={'sm'} src={AvatarUrl(userData?.avatar)} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm'>
                    {`${capitilizeFirstLetter(userData?.firstName) || ''} ${
                      capitilizeFirstLetter(userData?.lastName) || ''
                    }`}
                  </Text>
                  <Text fontSize='xs' color='gray.600'>
                    {capitilizeFirstLetter(userData?.status || '')}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem
                onClick={() => navigate('/profile')}
                _hover={menuStyles}
              >
                Profile
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => signOut()} _hover={menuStyles}>
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
//
const SidebarWithHeader = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [me, { loading, data, error }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    me();
  }, [userData]);

  useEffect(() => {
    if (data?.me) {
      if (userData?.authKey !== data?.me?.authKey) {
        dispatch(thunkLogout());
        navigate('/signin/');
      }
    }
  }, [data]);

  return (
    <Box bg={useColorModeValue('white.100', 'white.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='xs'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
    </Box>
  );
};
export default SidebarWithHeader;
