import { gql } from '@apollo/client';

// CREATE_MANUAL_TIME_REQUEST
export const CREATE_MANUAL_TIME_REQUEST = gql`
  mutation requestOfflineTime(
    $offlineTimeRequest: OfflineTimeInput!
    $toEmail: String!
  ) {
    requestOfflineTime(
      offlineTimeRequest: $offlineTimeRequest
      toEmail: $toEmail
    )
  }
`;

// CREATE_MANUAL_TIME_REQUEST_BY_HR
export const CREATE_MANUAL_TIME_REQUEST_BY_HR = gql`
  mutation approveManualOfflineTime(
    $data: CreateOfflineSlotInput!
    $toEmail: String!
  ) {
    approveManualOfflineTime(data: $data, toEmail: $toEmail) {
      id
    }
  }
`;

// GET_PM_LISTING
export const GET_PM_LISTING = gql`
  mutation getProjectManagers {
    getProjectManagers
  }
`;

// APPROVE_OFFLINE_TIME_BY_HR
export const APPROVE_OFFLINE_TIME_BY_HR = gql`
  mutation approveOfflineTime(
    $where: TimeWhereUniqueInput!
    $data: TimeApprovalInput!
  ) {
    approveOfflineTime(where: $where, data: $data) {
      id
    }
  }
`;

// APPROVE_OFFLINE_TIME_BY_PM
export const APPROVE_OFFLINE_TIME_BY_PM = gql`
  mutation approveByRequest(
    $where: TimeWhereUniqueInput!
    $approveByInput: TimeApprovalInput!
  ) {
    approveByRequest(where: $where, approveByInput: $approveByInput) {
      id
    }
  }
`;

// BULK_UPDATE_MANUAL_TIME_REQUESTS_BY_HR
export const BULK_UPDATE_MANUAL_TIME_REQUESTS_BY_HR = gql`
  mutation bulkUpdateManualTimeByHr(
    $where: TimeWhereInput!
    $data: TimeApprovalInput!
  ) {
    bulkUpdateManualTimeByHr(where: $where, data: $data)
  }
`;

// BULK_UPDATE_MANUAL_TIME_REQUESTS_BY_PM
export const BULK_UPDATE_MANUAL_TIME_REQUESTS_BY_PM = gql`
  mutation bulkUpdateManualTimeByPm(
    $where: TimeWhereInput!
    $approveByInput: TimeApprovalInput!
  ) {
    bulkUpdateManualTimeByPm(where: $where, approveByInput: $approveByInput) {
      count
    }
  }
`;
