import React from "react";

function Youtube() {
  return (
    <svg
      width='29'
      height='24'
      viewBox='0 0 29 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.4365 5.43662C27.2828 4.77115 26.9814 4.16428 26.5626 3.67675C26.1438 3.18922 25.6221 2.83812 25.0499 2.6586C22.9435 2 14.5 2 14.5 2C14.5 2 6.05647 2 3.95011 2.6551C3.37763 2.83403 2.85572 3.18494 2.4368 3.67256C2.01788 4.16018 1.7167 4.76736 1.5635 5.43312C1 7.88535 1 13 1 13C1 13 1 18.1147 1.5635 20.5634C1.87388 21.9156 2.78996 22.9806 3.95011 23.3414C6.05647 24 14.5 24 14.5 24C14.5 24 22.9435 24 25.0499 23.3414C26.2131 22.9806 27.1261 21.9156 27.4365 20.5634C28 18.1147 28 13 28 13C28 13 28 7.88535 27.4365 5.43662ZM11.8181 17.6943V8.30573L18.8092 12.965L11.8181 17.6943Z'
        fill='white'
      />
    </svg>
  );
}

export default Youtube;
